<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>Input Data Details</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable hover striped :items="items" :fields="fields" :noItemsView="{ noItems: 'No items to show.' }" >
                        </CDataTable>
                    </CCardBody>
                    <CCardFooter>
                        <CButton color="primary" @click="redirect_to(`/data/input/${$route.params.id}/update`)" class="mr-2">Update</CButton>
                        <CButton color="dark" @click="go_back">Back</CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
export default {
    data () {
        return {
            items: [],
        }
    },
    computed: {
        fields(){
            return [
                { key: 'key', label: ''},
                { key: 'value', label: ''}
            ]
        }
    },
    mounted(){
        this.get();
    },
    methods: {
        get() {
            this.axios.get('arbitrage/input_datas/'
                + '?id=' + this.$route.params.id
                + '&data_source_info=1')
                .then(res => {
                    res.data.map(val => {
                        switch(val.is_active){
                            case true:
                                val.is_active = 'Active';
                                break;
                            case false:
                                val.is_active = 'Inactive';
                                break;
                        }
                        if(val.updated_at != '0001-01-01T00:00:00Z'){
                            val.updated_at = this.$moment(val.updated_at).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if(val.last_insert != '0001-01-01T00:00:00Z'){
                            val.last_insert = this.$moment(val.last_insert).format('YYYY-MM-DD HH:mm:ss');
                        }
                        val.created_at = this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss');
                        val['pattern'] = '';
                        for (const key in val.pattern_info_data){
                            if(val.pattern_info_data[key] != ''){
                                val.pattern = val.pattern_info_data[key];
                                break;
                            }
                        }
                        this.items.push(
                            { key: 'ID', value: val.id },
                            { key: 'Name', value: val.name },
                            { key: 'Data', value: val.data },
                            { key: 'Data Source', value: val.data_source_info.name },
                            { key: 'Last Insert', value: val.last_insert },
                            { key: 'State', value: val.is_active },
                            { key: 'Description', value: val.detail },
                            { key: 'Pattern', value: val.pattern },
                            { key: 'Updated at', value: val.updated_at },
                        );
                    })
                })
        },
        redirect_to(route) {
            this.$router.push(route);
        },
        go_back(){
            this.$router.go(-1);
        }
    },
}
</script>
